// Copyright 2024 The LUCI Authors.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//      http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import { Box, Typography } from '@mui/material';

export const AllHeader = () => {
  return (
    <Box sx={{ padding: '16px' }}>
      <Typography variant="h5">
        All Failures
        <span style={{ fontSize: '14px', opacity: '50%', paddingLeft: '12px' }}>
          All current failures, including those already in groups.
        </span>
      </Typography>
    </Box>
  );
};
